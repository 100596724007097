import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { experimentalStyled as styled, keyframes } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FavoriteIcon from '@mui/icons-material/Favorite' // Import heart icon
import { useNavigate } from 'react-router-dom' // Import useNavigate

// Define fade-in animation with upward movement
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '200px', // Set a fixed height for the cards
  position: 'relative', // Needed for the grey bottom part
  overflow: 'hidden', // Ensure content doesn't overflow the card
  borderRadius: '20px', // Apply border radius to the whole card
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // Apply animation on load
  animation: `${fadeInUp} 0.6s ease-out`,
  animationDelay: '0.2s',
  animationFillMode: 'forwards', // Ensure the animation persists
  opacity: 0, // Initial opacity for animation

  // Add zoom effect on hover
  '&:hover img': {
    transform: 'scale(1.3)', // Zoom the image by 10%
  },
}))

const GreyBottom = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '50px',
  backgroundColor: '#616161', // Set the grey color for the bottom part
  borderBottomLeftRadius: '20px', // Apply border radius only to the bottom left corner
  borderBottomRightRadius: '20px', // Apply border radius only to the bottom right corner
  display: 'flex',
  justifyContent: 'space-between', // Space between title and like count
  alignItems: 'center',
  padding: '0 10px', // Add some padding for the title and likes
})

export function FullBlog() {
  const [blogs, setBlogs] = useState([])
  const [error, setError] = useState()
  const navigate = useNavigate() // Initialize the useNavigate hook

  useEffect(() => {
    // Define the async function to fetch the blogs
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          'https://sanctity-be-4yqw.onrender.com/getblogs',
          {
            withCredentials: true, // Ensures cookies (if any) are sent with the request
          },
        )
        setBlogs(response.data) // Store the blogs in the state
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching blogs:', error)
        setError('Failed to load blogs')
      }
    }

    fetchBlogs() // Call the function when the component mounts
  }, []) // Empty dependency array ensures this runs once

  // Function to handle blog card click
  const handleCardClick = (blogId) => {
    navigate(`/saveblog/${blogId}`) // Navigate to the blog URL
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginTop: '70px',
        marginBottom: '20px',
        height: 'calc(100vh - 130px)', // Subtract footer height (adjust as needed)
        overflowY: 'auto', // Enable vertical scrolling when needed
        padding: '20px',
        boxSizing: 'border-box', // Include padding in height calculation
        fontFamily:
          "'source-serif-pro', Georgia, Cambria, 'Times New Roman', Times, serif", // Set font family for the box
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center" // Center horizontally
      >
        {blogs.map((blog, index) => (
          <Grid item key={blog.blogId} xs={4} sm={4} md={4}>
            <Item
              onClick={() => handleCardClick(blog.blogId)} // Add click handler
              sx={{
                animationDelay: `${index * 0.4}s`, // Delay each card's animation
                cursor: 'pointer', // Change cursor to pointer on hover
              }}
            >
              <img
                src={blog.image}
                alt={`Card ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%', // Set height to auto to maintain aspect ratio
                  maxHeight: '100%', // Ensure it doesn't overflow the card height
                  objectFit: 'cover', // Ensure the image covers its container
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  transition: 'transform 0.3s ease', // Smooth zoom effect
                }}
              />
              <GreyBottom>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#fff',
                    whiteSpace: 'nowrap', // Prevents text from wrapping onto multiple lines
                    overflow: 'hidden', // Ensures the overflowing text is hidden
                    textOverflow: 'ellipsis', // Adds "..." at the end if the text overflows
                    fontFamily:
                      "'source-serif-pro', Georgia, Cambria, 'Times New Roman', Times, serif", // Set font family for the box
                  }}
                >
                  {blog.heading}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <FavoriteIcon sx={{ color: 'red', fontSize: '16px' }} />{' '}
                  {/* Decreased heart icon size */}
                  <Typography
                    variant="subtitle2"
                    sx={{ color: '#fff', fontSize: '14px' }}
                  >
                    {blog.likes}
                  </Typography>
                </Box>
              </GreyBottom>
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FullBlog
